<template>
  <div>
    <div class="row">
      <div class="column">
        <img src="../assets/imagegallery/snow1.jpg" style="width: 100%" />
        <img src="../assets/imagegallery/niagara.jpg" style="width: 100%" />
        <img src="../assets/imagegallery/toronto.jpg" style="width: 100%" />
        <img src="../assets/imagegallery/toronto3.jpg" style="width: 100%" />
      </div>
      <div class="column">
        <img src="../assets/imagegallery/snow2.jpg" style="width: 100%" />
        <img src="../assets/imagegallery/airport.jpg" style="width: 100%" />
        <img src="../assets/imagegallery/peacetower1.jpg" style="width: 100%" />
        <img src="../assets/imagegallery/ottawa.jpg" style="width: 100%" />
      </div>
      <div class="column">
        <img src="../assets/imagegallery/muskoka2.jpg" style="width: 100%" />
        <img src="../assets/imagegallery/muskoka1.jpg" style="width: 100%" />
        <img src="../assets/imagegallery/ski1.jpg" style="width: 100%" />
        <img src="../assets/imagegallery/toronto4.jpg" style="width: 100%" />

      </div>
      <div class="column">
        <img src="../assets/imagegallery/sk2.jpg" style="width: 100%" />
        <img src="../assets/imagegallery/snow3.jpg" style="width: 100%" />
        <img src="../assets/imagegallery/peacetower2.jpg" style="width: 100%" />
        <img src="../assets/imagegallery/new.jpg" style="width: 100%" />
      </div>
    </div>
    <iframe
      class="globe-div"
      frameborder="0"
      scrolling="no"
      src="https://globe.gl/example/choropleth-countries/"
      height="400"
    ></iframe>
  </div>
</template>


<script>
export default {};
</script>

<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.column {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .column {
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    flex: 100%;
    max-width: 100%;
  }
}

.globe-div {
  width: 80%;
  margin: 10px auto;
  display: block;
}
</style>