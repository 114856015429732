<template>
  <b-container fluid class="mt-2">
    <h3 class="clients-header">OUR CLIENTS</h3>
    <p>We have many clients in our history. Here are few repeat customers:</p>
    <ul>
      <li>Bentall Retail Services LP</li>
      <li>British Consulate (Toronto)</li>
      <li>CBI Health Canada</li>
      <li>CIBC</li>
      <li>Tricor Auto Leasing</li>
      <li>CWC</li>
      <li>EXEL</li>
      <li>Fulbright & Jaworski LLP (USA)</li>
      <li>General Electric (Oakville)</li>
      <li>Grant Thornton</li>
      <li>Grupo Destinos (Mexico)</li>
      <li>Harbinger Communications (Toronto)</li>
      <li>IT Exchange</li>
      <li>Laser Network (Toronto)</li>
      <li>Life is Wine (Toronto)</li>
      <li>Major League Baseball-USA</li>
      <li>MDS Capital (Toronto)</li>
      <li>Mileno Tours (Japan)</li>
      <li>NRI Industries</li>
      <li>Play The World Sports (Australia)</li>
      <li>ROST Consulting Inc</li>
      <li>Taka Jewellery (Singapore)</li>
      <li>Time For You Concierge Service</li>
      <li>TricorAuto</li>
      <li>990 Capital</li>

      <!-- Toromont Industries (Vaughan)
  Toronto French School (Mississauga)
  Urbacon (Toronto)
  Veritas Canada
  Westin Harbor Castle Hotel (Toronto) -->
    </ul>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      name: "zio",
    };
  },
};
</script>

<style scoped>
.clients-header {
  color: red;
  font-weight: bold;
}
</style>