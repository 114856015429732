<template>
    <div>
      <h1>Oops, looks like something went wrong!</h1>
      <router-link to="/contactus">
        <button>Back to form</button>
      </router-link>
    </div>
  </template>
  
  <script>
    export default {
      name: "SubmissionFail"
    }
  </script>