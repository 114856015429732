<template>
    <div>
      <h1>Thank you for your submission!. We will get back to you ASAP</h1>
      <router-link to="/contactus">
        <button>Back to form</button>
      </router-link>
    </div>
  </template>
  
  <script>
    export default {
      name: "SubmissionSuccess"
    }
  </script>